<template>
  <div>
    <div>
      <b-row style="border-bottom: 3px solid black">
        <b-col class="d-flex justify-content-center">
          <img
            src="@/assets/inter.png"
            class="thumbnail p-0 m-0 mb-2"
            width="70px"
            alt=""
          />
        </b-col>
        <b-col
          class="d-flex justify-content-center"
          style="border-right: 3px solid black; border-left: 3px solid black"
          ><span class="strong lead">{{ bankTicket.bankNumber }}</span></b-col
        >
        <b-col md="8" class="d-flex align-items-center justify-content-end p-0"
          ><span>{{ digitableLine }}</span>
        </b-col>
      </b-row>
      <b-row
        style="
          border-left: 3px solid black;
          border-right: 3px solid black;
          border-bottom: 3px solid black;
        "
      >
        <b-col>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title"
                >Agência/Código do Beneficiário</span
              >
              <span class="boleto-cel-value"
                >{{ unityData.agency
                }}<template v-if="unityData.agency_d"
                  >-{{ unityData.agency_d }}</template
                ></span
              >
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Número do documento</span>
              <span class="boleto-cel-value">{{
                invoice.number_document
              }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Nosso Número</span>
              <span class="boleto-cel-value">{{ invoice.number }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Data de Vencimento</span>
              <span class="boleto-cel-value">
                {{ vencimentDay }}
              </span>
            </b-col>
            <b-col>
              <b-col
                class="d-flex flex-column align-items-start boleto-cel"
                style="border-left: 1px solid black"
              >
                <span class="boleto-cel-title">(=)Valor do documento</span>
                <span class="boleto-cel-value" style="font-weight: 800">{{
                  toMonetary(invoice.total)
                }}</span>
              </b-col>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Pagador</span>
              <span class="boleto-cel-value" style="font-weight: 900">{{
                bankTicket.payer
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Informações ao Pagador</span>
              <span class="boleto-cel-value">
                {{ bankTicket.payerInfo }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col md="3" style="border-left: 1px solid black">          
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor do documento</span>
              <span class="boleto-cel-value" style="font-weight: 800">{{
                toMonetary(invoice.total)
              }}</span>
            </b-col>
          </b-row>
          
        </b-col> -->
      </b-row>
      <b-row style="height: 80px">
        <b-col class="d-flex justify-content-center">
          <span style="font-size: 8px">Autenticação Mecânica</span>
        </b-col>
      </b-row>
    </div>
    <hr class="dashed mt-4" />
    <div>
      <b-row style="border-bottom: 3px solid black">
        <b-col class="d-flex justify-content-center">
          <img
            src="@/assets/inter.png"
            class="thumbnail p-0 m-0 mb-2"
            width="70px"
            alt=""
          />
        </b-col>
        <b-col
          class="d-flex justify-content-center"
          style="border-right: 3px solid black; border-left: 3px solid black"
          ><span class="strong lead">{{ bankTicket.bankNumber }}</span></b-col
        >
        <b-col md="8" class="d-flex align-items-center justify-content-end p-0"
          ><span>{{ digitableLine }}</span></b-col
        >
      </b-row>
      <b-row
        style="
          border-left: 3px solid black;
          border-right: 3px solid black;
          border-bottom: 3px solid black;
        "
      >
        <b-col md="9">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Local de Pagamento</span>
              <span class="boleto-cel-value">{{
                bankTicket.paymentPlace
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Beneficiário Final</span>
              <span class="boleto-cel-value" style="font-weight: 900">{{
                `${unityBankAccount.beneficiary_document}, 
                ${unityBankAccount.beneficiary_name}, ${unity.address}`
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Intermediado por:</span>
              <span class="boleto-cel-value"
                >Colmeia.io - 12.345.678/0001-00</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Data do documento</span>
              <span class="boleto-cel-value">{{ documentDate }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Número do documento</span>
              <span class="boleto-cel-value">{{
                invoice.number_document
              }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Espécie Doc.</span>
              <span class="boleto-cel-value">{{ bankTicket.typeDoc }}</span>
            </b-col>
            <b-col
              md="1"
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Aceite</span>
              <span class="boleto-cel-value">{{ bankTicket.agreed }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Data do Processamento</span>
              <span class="boleto-cel-value">{{
                parseDate(invoice.created_at)
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Uso do Banco</span>
            </b-col>
            <b-col
              md="2"
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Carteira</span>
              <span class="boleto-cel-value">{{ bankTicket.wallet }}</span>
            </b-col>
            <b-col
              md="2"
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Espécie</span>
              <span class="boleto-cel-value">{{ bankTicket.currency }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Quantidade</span>
              <span class="boleto-cel-value">{{ bankTicket.quantity }}</span>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-left: 1px solid black"
            >
              <span class="boleto-cel-title">Valor</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title"
                >Instruções de responsabilidade do beneficiário. Qualquer dúvida
                sobre este boleto, contate o beneficiário.</span
              >
              <span class="boleto-cel-value">{{
                bankTicket.instructions
              }}</span>
              <br /><br />
            </b-col>
          </b-row>

          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Pagador:</span>
              <span class="boleto-cel-value">{{
                financialClient.financial_name
              }}</span>
              <span class="boleto-cel-value"
                >{{ financialClientAddress.address }},
                {{ financialClientAddress.number }} -
                {{ financialClientAddress.district }} -
                {{ financialClientAddress.city }} -
                {{ financialClientAddress.state }} - CEP:
                {{ financialClientAddress.zip_code }}</span
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col md="3" style="border-left: 1px solid black">
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">Vencimento</span>
              <span class="boleto-cel-value">{{ vencimentDay }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-bottom: 1px solid black"
            >
              <span class="boleto-cel-title"
                >Agência/Código do Beneficiário</span
              >
              <span class="boleto-cel-value"
                >{{ unityData.agency
                }}<template v-if="unityData.agency_d"
                  >-{{ unityData.agency_d }}</template
                ></span
              >
            </b-col>
          </b-row>
          <b-row v-if="invoice.number">
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-bottom: 1px solid black"
            >
              <span class="boleto-cel-title">Nosso Número</span>
              <span class="boleto-cel-value">{{ invoice.number }}</span>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col
              class="d-flex flex-column align-items-start boleto-cel"
              style="border-bottom: 1px solid black"
            >
              <span class="boleto-cel-title">Nosso Número</span>
              <span class="boleto-cel-value">&nbsp;</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor do documento</span>
              <span class="boleto-cel-value" style="font-weight: 800">{{
                toMonetary(invoice.total)
              }}</span>
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(-)Desconto/Abatimento</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(-)Outras Deduções</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Mora/Multa/Juros</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row style="border-bottom: 1px solid black">
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(+)Outros Acréscimos</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col class="d-flex flex-column align-items-start boleto-cel">
              <span class="boleto-cel-title">(=)Valor cobrado</span>
              <span class="boleto-cel-value" style="font-weight: 800"
                >&nbsp;</span
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" class="p-0">
          <div class="m-2" v-html="barcode"></div>
        </b-col>
        <b-col class="d-flex justify-content-end p-0">
          <span style="font-size: 8px">Autenticação Mecânica - </span>
          <span style="font-size: 8px">&nbsp; FICHA DE COMPENSAÇÃO</span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Boleto from "boleto.js";
import moment from "moment";
export default {
  data() {
    return {
      bank: "",
      bankTicket: {
        bankNumber: "077-9",
        payer: "",
        payerInfo: "",
        paymentPlace: "PAGÁVEL EM QUALQUER BANCO ATÉ O VENCIMENTO",
        typeDoc: "DM",
        agreed: "A",
        currency: "R$",
        instructions:
          "SR. CAIXA, FAVOR NÃO COBRAR JUROS E RECEBER APÓS O VENCIMENTO",
        wallet: "112",
      },
    };
  },
  computed: {
    unity() {
      return this.$store.state.checkout.clientData.unity[0];
    },
    unityData() {
      return this.$store.state.checkout.unityData[0];
    },
    invoice() {
      return this.$store.state.checkout.clientData.invoice[0];
    },
    unityBankAccount() {
      return this.$store.state.checkout.clientData.unityBankAccount[0];
    },
    financialClient() {
      return this.$store.state.checkout.clientData.financialClient;
    },
    financialClientAddress() {
      let address = {};
      try {
        address = JSON.parse(this.financialClient.address);
      } catch (e) {
        address = this.financialClient.address;
      }
      return address;
    },
    vencimentDay() {
      return moment(this.invoice.venciment_day)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
    documentDate() {
      return moment(this.invoice.created_at)
        .add("hours", "3")
        .format("DD/MM/YYYY");
    },
    barcode() {
      try {
        return new Boleto(this.invoice.line).toSVG();
      } catch (e) {
        return "erro ao gerar código de barras";
      }
    },
    digitableLine() {
      try {
        return new Boleto(this.invoice.line).prettyNumber();
      } catch (e) {
        return "erro ao gerar linha digitável";
      }
    },
  },
  mounted() {


    this.bankTicket.payer = this.financialClient.financial_name.toUpperCase();
    this.bankTicket.payerInfo = "Informações";

    
  },
};
</script>

<style lang="scss" scoped>
.boleto-cel-title {
  line-height: 1;
  font-size: 7px;
  font-weight: 700;
}
.boleto-cel-value {
  line-height: 1.5;
  font-size: 10px;
}
.boleto-cel {
  padding: 2px;
}
hr.dashed {
  border-top: 1px dashed black;
}
.bank-ticket {
  background-color: rgb(235, 235, 235);
  padding: 2px 10px;
}
.card-title-custom {
  font-size: 7px;
}
.card-body-custom {
  font-size: 10px;
}
</style>